<template>
  <div class="guide-main pdHeader">
    <common-header
      class="red"
      :title="type === '0' ? '网站服务协议' : '个人信息保护政策'"
    ></common-header>

    <!-- 网站服务协议 start -->
    <div class="sign-register pre agree-scroll" v-if="type === '0'">
      <!-- <div class="agree-tit tc">网站服务协议</div> -->
      <div class="agree-text">
        <p>
          尊敬的用户，欢迎您访问
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.orgFullName }}</a
          >（以下简称“本站”）官方网站。
        </p>
        <p>
          本协议是您（或称"用户"）与
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.orgFullName }}</a
          >（以下简称“本站”）之间就使用“网站”软件服务所订立的契约。在注册或通过移动手机号码登录本网站前，请您仔细阅读本协议的全部内容，特别是协议中以加粗字体显示的内容，您应重点阅读。如您对协议有任何疑问，可向本站咨询。如果您不同意本协议或其中任何条款约定，请您立即停止注册或登录；您点击"同意"按钮后，本协议即构成对双方有约束力的法律文件。
        </p>
        <p class="tit">一、定义</p>
        <p>
          1、本网站：在本协议中除非专门指明或声明，均指由
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.orgFullName }}</a
          >所有并负责运营的网站。
        </p>
        <p>
          2、所有权以及相关的权利：在本服务协议中，除非专门指明或声明，均意味着：第一，所有权；第二，知识产权，包括但不限于著作权、专利权、商标权、商业秘密等；第三，除上述权利之外的对物、信息及其载体和表现形式的使用、利用、传播、复制、发行、编辑、修改、处分等权利。
        </p>
        <p>
          3、用户：在本服务协议中，除非专门指明或声明，是指接受本服务协议的自然人、法人或其他组织。
        </p>
        <p>
          4、个人信息：在本服务协议中，除非专门指明或声明，是指以电子或者其他方式记录的能够单独或者与其他信息结合识别用户身份或者反映用户活动情况的各种信息。
        </p>
        <p>
          5、网络服务：指本网站基于互联网方式向用户提供的业务宣传、业务查询、业务办理、商品展示、商品交易等服务。
        </p>
        <p class="tit">二、网络服务内容</p>
        <p>1、本网站向用户提供的网络服务包括：</p>
        <p>
          （1）用户注册、登录、安全退出、找回密码、修改密码、基本资料修改、信息查询等服务；
        </p>
        <p>（2）个人信息登记、业务办理、业务展示；</p>
        <p>（3）商品交易服务；</p>
        <p>（4）发布的文字、软件、声音、图片、录像、图表、广告中的全部内容；</p>
        <p>（5）网站消息通知、微信通知、短信通知服务；</p>
        <p>（6）本网站为用户提供的其他服务。</p>
        <p>
          2、本网站有权根据情况变化调整网络服务的具体内容，本网站提供的自助业务办理功能和商品信息随时都有可能发生变动，对此本站不作特别通知，也不对网络服务的提供或不提供作出承诺。
        </p>
        <p>
          3、本网站仅提供网络服务，与网络服务有关的通信设备（如电脑、调制解调器及其他与接入互联网有关的装置）及通信服务所需的费用（如为接入互联网而支付的电话费及宽带费）由用户自行承担。
        </p>
        <p class="tit">三、权利声明</p>
        <p>
          1、任何本网站所发布的信息及/或其载体，及/或其表现形式，及/或服务，除以下情况之外，其所有权及相关的权利均独占性地属于本站所有：
        </p>
        <p>
          （1）依据中华人民共和国的相关法律规定该信息及/或载体，及/或服务，不享有所有权以及与此相关的权利的；
        </p>
        <p>
          （2）依据本站和其他主体之间的协议、合同、章程，由本站和其他主体共享，或其他主体单独享有或本站转让的；
        </p>
        <p>（3）依据本站具有法律效力的正式声明而放弃的。</p>
        <p>
          2、用户只能在本网站和内容提供者授权下才能使用由本网站享有权利的内容，且无权擅自复制、修改这些内容，或创造与内容有关的派生产品。任何对本网站享有的基于该信息及/或其载体，及/或其表现形式，及/或服务的权利的侵犯，均属于对本网站权利的侵犯。
        </p>
        <p class="tit">四、用户账号注册与管理</p>
        <p>
          1、用户在完成注册或登录程序实际使用本网站提供服务时，应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。若用户不具备前述主体资格，则用户及用户的监护人应当依照法律规定承担因此而导致的一切后果，本站有权注销或永久冻结您的账户。
        </p>
        <p>
          2、如果您是微信用户，无需注册，可以直接进入；您也可以通过本网站进行注册，获得在本网站的帐号。在完成注册、激活或验证流程时，您应当按照相应页面的提示提供您的身份信息及资料，您应保证提供的身份信息及资料真实、及时、完整和准确。
        </p>
        <p>
          3、您在本网站设置的用户名不得违反国家法律法规关于互联网用户账号名称的管理规定，否则本网站将有权回收您的用户名。用户名的回收不会影响您以邮箱、手机号码登录本网站并使用本网站服务。
        </p>
        <p>
          4、您在本网站的账号、密码和手机短信验证码是您在本网站的唯一身份权证。为享受本网站提供的网络服务，您须按照对应的服务要求键入正确的用户账号及密码或短信验证码。
        </p>
        <p>
          5、您应保管好自己在本网站的账号、密码和手机短信验证码，避免使用过于简单的密码。您在服务使用过程中可以根据本网站规定更改密码。非经本网站事先同意，账号和密码不得出借、转让、继承或者赠与。非因本网站原因导致您的账号、密码遗失、遗忘或被他人窃取的，本网站不承担责任。如果您发现自己的个人信息泄密，尤其是本网站账户及密码发生泄露，请您立即联络本网站或本站。
        </p>
        <p>
          6、用户通过账号+密码和/或短信验证码登录本网站办理的一切业务，均视为用户亲自办理的业务，由用户承担因此所导致的相关后果和责任，包括但不限于业务费用的支付。
        </p>
        <p>
          7、本站将采取相应的措施来保证用户的帐户和交易安全，但本站不保证其绝对安全。
        </p>
        <p class="tit">五、用户权利及其限制</p>
        <p>
          1、在遵守本服务协议的前提下，用户有权享有本网站提供的网络服务，并将收到本网站最新的优惠产品和服务的相关信息。如您不愿意接受本网站的信息，您有权要求本网站停止向您发送产品和服务信息。
        </p>
        <p>
          2、用户承诺对网络服务的使用应符合国家法律法规的规定，且行为符合下列要求：
        </p>
        <p>（1）从中国境内向境外传输技术性资料时必须符合中国有关法律的规定；</p>
        <p>
          （2）不传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；
        </p>
        <p>
          （3）不传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；
        </p>
        <p>（4）不得传输或发表违背公序良俗和涉及国家安全的信息资料或言论；</p>
        <p>
          （5） 不干扰本网站的正常运转，不得侵入本网站及国家计算机信息系统；
        </p>
        <p>
          （6）不得发布任何侵犯他人著作权、商标权等知识产权或隐私权、名誉权等合法权益的内容；
        </p>
        <p>（7）遵守所有使用网络服务的网络协议、规定、程序和惯例；</p>
        <p>（8）使用网络服务不作非法用途；</p>
        <p>
          若用户未遵守以上规定的，须对自己在网上的言论和行为承担法律责任，且本站有权作出独立判断并采取暂停或关闭用户帐号服务等措施。
        </p>
        <p>
          3、本网站对用户发布及或通过本网站传送的任何内容不承担责任，用户需对自己在网上的行为承担一切法律责任。用户如在本网站发布涉嫌违法或涉嫌侵犯他人合法权利或违反本协议的信息，本网站有权不经通知用户即予以删除。用户如有散布和传播反动、色情或其他违反国家法律信息的活动，本网站的系统记录有可能作为用户违反法律的证据。
        </p>
        <p>
          4、用户违反本协议使用网络服务导致本网站或第三人损失的，因此产生的损害赔偿费用、律师费用及其他相关费用，均由该用户承担。
        </p>
        <p class="tit">六、 商品购买</p>
        <p>
          1、在您下订单购买商品时，请您仔细确认所购商品的名称、价格、数量、型号、联系地址、电话、收货人等信息。收货人与用户本人不一致的，收货人/用户的行为和意思表示视为用户/收货人的行为和意思表示，用户/收货人应对收货人/用户的行为及意思表示的法律后果承担连带责任。
        </p>
        <p>
          2、除法律另有强制性规定外，双方约定如下：本网站中销售方展示的商品和价格等信息仅仅是要约邀请，您下单时须填写您希望购买的商品数量、价款及支付方式、收货人、联系方式、收货地址（合同履行地点）、合同履行方式等内容；系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向销售方发出的合同要约；销售方收到您的订单信息后，只有在销售方将您在订单中订购的商品从仓库实际直接向您发出时（以订单状态已发货为标志），方视为您与销售方之间就实际直接向您发出的商品建立了合同关系；如果您在一份订单里订购了多种商品并且销售方只给您发出了部分商品时，您与销售方之间仅就实际直接向您发出的商品建立了合同关系；只有在销售方实际直接向您发出了订单中订购的其他商品时，您和销售方之间就订单中该其他已实际直接向您发出的商品才成立合同关系。您可以随时登录您在本网站注册的账户，查询您的订单状态。
        </p>
        <p>
          由于市场变化及各种以合理商业努力难以控制的因素的影响，本网站无法保证您提交的订单信息中希望购买的商品都会有货；如您拟购买的商品，
          发生缺货，您有权取消订单。此等情况，并不视为本站违约，本站没有义务向您作出任何赔偿。
        </p>
        <p>
          3、本网站将会把商品送到您所指定的送货地址。所有在本网站上列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。请清楚准确地填写您的真实姓名、送货地址及联系方式。因如下情况造成订单延迟或无法配送等，本网站将无法承担迟延配送的责任：
        </p>
        <p>（1）用户提供错误信息和不详细的地址；</p>
        <p>
          （2）货物送达无人签收，由此造成的重复配送所产生的费用及相关的后果。
        </p>
        <p>（3）不可抗力，例如：自然灾害、交通戒严、突发战争等。</p>
        <p>（4）法定及特殊节假日期间，物流商无法正常配送的。</p>
        <p class="tit">七、用户个人信息保护</p>
        <p>
          1、
          用户应保证自己在注册或登录时用户身份的真实性和正确性及完整性，如果相关信息发生变化，用户应及时更改。本网站不能也不会对个人信息的不真实、不准确，或个人信息未及时更新而引起的任何损失或损害承担责任。本网站有权在投诉人准确说出用户在注册时所用的手机号码、身份证号码和用户姓名后删除该用户，并不承担任何责任。遇此情况，请用户重新注册。
        </p>
        <p>
          2、用户未能按照本网站的要求和内容提供本网站要求的必要信息的，本网站有权中止本网站所提供的服务，由此所导致的任何已经或可能发生的损失，本网站概不负责，并保留对该用户进行追索的权利。本网站有权对用户的注册信息及相关操作进行查阅，发现注册信息或相关操作中存在任何问题或怀疑，均有权向用户发出询问及要求改正的通知或者直接作出删除账户等处理。
        </p>
        <p>
          3、本站非常重视用户个人信息的保护，在您使用本站提供的网络服务时，您同意本站按照在本网站公布的个人信息保护政策收集、存储、使用、披露和保护您的个人信息。本站希望通过《个人信息保护政策》向您清楚地介绍本站对您个人信息的处理方式，因此建议您完整地阅读《个人信息保护政策》，以帮助您更好地保护您的个人信息。
        </p>
        <p class="tit">八、免责声明</p>
        <p>
          1、本网站不就通信系统或互联网的中断或无法运作、技术故障、计算机错误或病毒、信息损坏或丢失或其它在本网站合理控制范围之外的原因而产生的其他任何性质的破坏而向用户或任何第三方承担赔偿责任。
        </p>
        <p>
          2、本网站需要定期或不定期地对相关的信息网络系统、设备进行检修、维护或升级，如因此类情况而造成网络服务在合理时间内的中断，本网站无需为此承担任何责任，但本网站将尽可能事先进行通知。
        </p>
        <p>
          3、本站不保证用户在使用本网站时在操作上不会中断或没有错误，不保证会纠正本网站所有缺陷，不保证本网站能满足用户的所有要求，亦不保证用户能通过本网站订购所有本站的业务和产品，对网络服务的及时性、安全性、准确性也都不作担保。
        </p>
        <p>
          4、用户通过本网站进行交易、获取有偿服务而发生的所有税费及有关费用，均由用户负责支付，因此产生的风险也由用户个人承担。本站提醒用户应该通过自己的谨慎判断确定陈列商品及相关信息的真实性、合法性和有效性。
        </p>
        <p>
          5、双方承认本条款反映了双方就协商谈判达成的一致意见。双方均完全了解本条款的后果并进一步承认本条款的合理性。
        </p>
        <p class="tit">九、网络服务的停止</p>
        <p>
          1、在以下情形下，本网站有权单方并在不做出任何通知的情况下立即停止对用户提供网络服务:
        </p>
        <p>
          （1）若该用户是通过手机号码及服务密码/或短信验证码登陆的，当该用户所对应的手机号码停机或销号后；
        </p>
        <p>（2）用户违反本协议规定使用网络服务；</p>
        <p>（3）用户提供的注册资料不真实；</p>
        <p>（4）用户账户被删除后；</p>
        <p>
          （5）
          若用户利用本网站提供的邮件服务发送了垃圾邮件或其他有害信息，则本网站有权停止为该用户提供邮件服务。
        </p>
        <p>
          2、用户对本协议后续的修改有异议，或对本网站的服务不满，可以行使如下权利：
        </p>
        <p>（1）停止使用本网站的网络服务；</p>
        <p>（2）通告本网站停止对该用户的服务；</p>
        <p>
          3、用户服务停止后，本网站没有义务向该用户或第三方传送任何未处理的信息或未完成的服务，亦无需对该用户或第三方负责。但本协议的终止并不意味着终止前所发生的未完成用户指令的撤销，也不能消除因终止前的交易所带来的法律后果。
        </p>
        <p class="tit">十、第三方链接</p>
        <p>
          1、从本网站链接至本站以外的网站：某些情况下，本网站会提供跳转至国际互联网上的其它页面或网站的链接。此链接将会引您至第三方发行或经营的网站，而该第三方并非本站的合作机构或与本站有任何联系。本站将该链接列入网站内，仅为协助用户浏览和参考之用。本站致力于挑选声誉良好的网站和资料来源，以方便用户。然而，除非本站已经明确声明与该第三方有合作关系，提供链接至此第三方网站或网页，并不视为本站同意、推荐、认可、保证或推介任何第三方或在第三方网站上所提供的任何服务、产品，亦不可视为本站与该第三方及其网站有任何形式的合作。
        </p>
        <p>
          2、从本网站链接至本站其它网站：本网站亦包含链接至本站其它网站，以方便您的使用。该网站所提供的产品和服务只提供给身处或居所属于该司法管辖地区的人士使用。本站其他网站各自制定使用条款，条款可能互有差异，您应先仔细查阅适用的使用条款，然后才使用相关的网站。
        </p>
        <p>
          3、从其他网站链接至本网站：若您想在第三方的网站以任何形式建立链接至本网站，必须先取得本站的书面同意,本站有权决定是否核准建立此链接。凡从第三方网站建立任何链接至本网站，本站不负责该链接的建立与设置。依此建立的链接，并不构成本站与该第三方网站有任何形式的合作，亦不构成本站对该第三方网站的认同。如因该链接而产生或导致的任何需由您或第三方承担或蒙受的损失或损害，本站不承担任何责任。对于经由本站核准以纯文字格式或任何形式建立的链接，本站保留随时撤销核准的权利，并有权要求清除任何指向本网站的链接。
        </p>
        <p class="tit">十一、通告</p>
        <p>
          所有发给用户的通告都可通过页面的公告或电子邮件或常规的信件传送。服务条款的修改、服务变更、或其它重要事件的通告都会以此形式进行。
        </p>
        <p class="tit">十二、服务条款修改</p>
        <p>
          本网站有权根据法律法规变化和网站运营需要，对本协议条款不时地进行修改。本协议一旦发生变动，本网站将通告用户，修改后的协议一经通告即生效。用户如果不同意所改动的内容，应主动停止使用本网站提供的网络服务。如果用户继续享用本网站网络服务，则视为接受服务条款的变动。
        </p>
        <p class="tit">十三、法律</p>
        <p>
          1、本协议条款适用中华人民共和国的法律法规。如协议条款与中华人民共和国法律相抵触，则依照法律的规定重新解释该服务条款，但不影响其它条款的法律效力和影响。
        </p>
        <p>
          2、本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律并受中华人民共和国法院管辖。
        </p>
        <p>3、用户与本网站一致同意：</p>
        <p>（1）双方应持平等、友好、争取合理解决问题的态度；</p>
        <p>
          （2）发生纠纷时首先以协商方式解决，如协商未果，任何一方应向本站通信有限公司所在地人民法院提起诉讼。
        </p>
        <p class="tit">十四、其他</p>
        <p>1、本站未行使本协议的任何权利或规定，并不构成对前述权利之放弃。</p>
        <p>
          2、如本协议中的任何条款完全或部分无效，本协议的其余条款仍有效并且有约束力。
        </p>
        <p>
          3、本协议内容包括协议正文及本网站上发布的声明和规则，包括但不限于购物指南、服务说明、个人信息保护政策等。所有声明、政策和规则等与协议正文具有同等法律效力，共同构成完整服务协议。
        </p>
      </div>
    </div>

    <!-- 网站服务协议 end -->

    <!-- 个人信息保护政策 start -->

    <div class="sign-register pre agree-scroll" v-else-if="type === '1'">
      <!-- <div class="agree-tit tc">个人信息保护政策</div> -->
      <div class="agree-text">
        <p>
          为切实保护本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）用户隐私权,优化用户体验，
          <b class="name">{{ orgInfo.orgFullName }}</b
          >（“本站”）根据现行法规及政策，制定本《个人信息保护政策》。本《个人信息保护政策》将详细说明本站在获取、管理及保护用户个人信息方面的政策及措施，请您务必仔细阅读。本《个人信息保护政策》适用于本站向您提供的所有服务，无论您是通过计算机设备、移动终端或其他设备获得的本站服务。请特别注意，您向我们提供相关个人信息并访问本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >），意味着您接受并且同意本政策的各项规定。
        </p>
        <p class="tit">个人信息的收集</p>
        <p>
          法律保护能够识别公民个人身份和涉及公民个人隐私的信息，这也是本《个人信息保护政策》的关注焦点。这些信息包括但不限于您的姓名、地址、电话号码、电子邮件地址、婚姻状况、财产状况等。通常，本站收集您的个人信息途径包括但不限于以下情况：
        </p>
        <p>·您注册成为网站用户;</p>
        <p>·您向我们发送电子邮件;</p>
        <p>·您使用我们的电子商务服务;</p>
        <p>
          在一般情况下，我们不会将您的个人信息公开给任何人，但是，如果您使用了我们的各类网站服务，意味着您同意将参与该服务时提供的信息公开给任何参与同一服务的其他人。本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）在不同的时间段，信息收集重点有所不同。 例如：
        </p>
        <p class="tit2">1.为您提供服务所必需的个人信息</p>
        <p>
          您第一次加入本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）时，需要您自愿提供诸如姓名、性别、年龄、电话、手机、住址、电子邮箱、婚姻状况、学历、收入状况、家庭状况、照片、兴趣爱好等个人信息以及网站自动接收并记录会员的浏览器和服务器日志上的信息(含会员登录所在地的IP地址、在线、无线信息、信件资料等。这些信息将用于提供网站服务、改进网页内容，满足会员对某种产品、活动的需求、通知会员最新产品、活动信息、或根据法律法规要求的用途。您在本网站上公开发布的个人信息，任何登陆到本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）或使用本网站的第三人均可查看的全部或部分可以被本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）的使用者查看，以提高您通过本站相亲成功的机会。您提供这些信息即表示您同意我们不同程度地向本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）的使用者披露。请注意，我们网站的任何用户（无论是否注册或登录）都可以访问、阅览和打印您所提供的并公开发布在网站上全部或部分信息，对于他人使用您在我们网站所提供的信息，我们不承担任何责任。
          请注意，我们网站的任何用户（无论是否注册）都可以访问、下载和打印您所提供的除真实姓名和个人联系方式以外的信息。对于他人使用或公开您在我们网站所提供的信息，我们不承担任何责任。
        </p>
        <p>
          同时，一旦您使用本站或成为会员（无论是付费还是免费），则表示您已许可我们将这些信息披露给我们的附属公司、母公司和相关联的第三方商业机构（包括但不限于本站线下VIP婚介服务授权店），以及其他和我们处于共同公司控制权的有关联的法律实体和商业机构。但是，我们同样会要求上述这些法
          律实体和商业机构在使用和披露这些个人信息时遵守本隐私政策的相关条款。我们也可能会在法律规定或政府机关要求的情况下，即为了回应传票、许可证、法院命令、政府机关指令或其他法律程序，为免除您在生命、身体或财产方面之急迫危险，为防止他人权益之重大危害，提高用户的使用安全性并提供客户支持，及其他维护用户及本站利益的情形下，去披露这些信息。
          所有相关的个人信息将会转移到计算机网络中进行维护，在符合当地法律规定的前提下，计算机网络可能位于您居住地以外的州/省、国家/地区或其他政府管辖区域，在这些国家/地区可能没有像您所在的国家/地区那样对个人信息给予同等力度的法律保护。
        </p>
        <p class="tit2">
          2.收集非个人可识别信息（特定或集合信息）以提高我们的服务质量
        </p>
        <p>
          除提供服务所必需的个人信息之外，我们还会收集一些数据（并非可识别的个人信息）来提高本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）的服务质量和改进网站管理。例如，当您浏览某一个网页时，系统会自动记录下URL、日期与时间以及所使用的浏览器软件等信息。
        </p>
        <p>
          收集特定或集合的信息有助于保证帐户的准确性，也有助于我们更好地为您提供服务，并帮助您最大限度地有效使用我们的网站。例如，我们的服务器会识别您的域名和所访问的网页。这些数据会以集合的方式收集，以帮助我们提高服务的质量和改进网站管理。
        </p>
        <p>
          本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）会不定期地向值得信任的第三方提供有关销售、浏览模式和导航技术方面的综合统计信息（这些并非是个人可识别信息），旨在帮助本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）改进其所提供的产品与服务。
        </p>
        <p class="tit2">
          3.自愿提供的个人信息 - 如为本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）的问卷调查、时事通讯以及第三方提供的信息
        </p>
        <p>
          我们通过问卷调查、竞赛和注册页面收集您自愿提供的信息，并仅将这些信息用于改进您的本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）在线体验。一切问卷调查将严格保密。在一般情况下，我们不会泄露、披露、出售、散发、出租、许可、共享您的个人信息或将其转让给任何第三方。
        </p>
        <p>
          本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）会不定期地与其他组织合作，为您提供特别建议、竞赛活动和订阅服务。
        </p>
        <p>
          请认真阅读这些活动及服务的条款和内容，如您自愿将您的可识别个人信息提供给第三方，相关责任将由您自行承担。
        </p>
        <p>
          本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）在与第三方建立业务关系时一向小心谨慎，如果不符合上述全部要求，则不会与第三方公司或组织共享、披露或出售我们网站所收集到的任何个人可识别信息。
        </p>
        <p class="tit2">4.信息查询和更新。</p>
        <p>
          您可以通过如下途径查询您的个人信息：（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >），如果您所提供的个人身份资料信息发生变更的，应当及时、有效地在本站网站个人信息设置中同步更新（个人信息更新链接为：
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >），本站有权对您更新的信息真实性和合法性进行审核。若您所提供的资料与事实不符或所提供的资料业已变更而未更新或有任何误导之嫌导致本站无法为您进一步提供服务，本站公司不因此承担任何责任。
        </p>
        <p class="tit2">5.通过电话、短信或电子邮件与您联系</p>
        <p>
          为了改善对您的服务，我们将会通过您提供的电话或电子邮箱等途径与您联系。例如，提供相关服务，回答针对我们的网站或服务的反馈，提供您所要求的任何信息，发送业务流程审查和确认信息，推荐新产品或新服务，发送电子邮件收讫确认等。
        </p>
        <p class="tit">个人信息的使用</p>
        <p>为了向您提供更好的服务或产品，本站会在下述情形使用您的个人信息：</p>
        <p>1)根据相关法律法规的要求；</p>
        <p>2)根据您的授权；</p>
        <p>3)根据本站相关服务条款、应用许可使用协议的约定</p>
        <p>
          此外，您已知悉并同意：在现行法律法规允许的范围内，本站可能会将您非隐私的个人信息用于市场营销，使用方式包括但不限于：在本站平台中向您展示或提供广告和促销资料，向您通告或推荐本站的服务或产品信息，以及其他此类根据您使用本站服务或产品的情况所认为您可能会感兴趣的信息。其中也包括您在采取授权等某动作时选择分享的信息，例如当您发送名片或站内邮件、在动态中新增地标、使用本站互动、联络工具等。
        </p>
        <p>
          未经您本人允许，本站不会向任何第三方披露您的个人信息，下列情形除外：
        </p>
        <p>1）您明确同意让第三方共享资料；</p>
        <p>2）只有透露您的个人信息，才能提供您所要求的产品和服务；</p>
        <p>3）为保护本网站、会员及社会公众的财产权利或人身权利；</p>
        <p>4）根据法律法规的规定，向司法机关提供；</p>
        <p>
          5）因用户使用本网站特定功能或因用户有潜在婚恋服务意愿，向代表我们提供产品或服务的合作单位或授权单位（包括但不限于本站线下技术服务）提供您的个人信息；
        </p>
        <p>6）受到黑客攻击导致会员信息泄漏的；</p>
        <p>7）根据您与本站相关服务条款、应用许可使用协议的约定；</p>
        <p>8）为实现使用目的，其他有必要的情况及法律法规规定的其他情形。</p>
        <p class="tit">个人信息的保护</p>
        <p>
          我们采用高科技的技术措施来确保您敏感信息的安全，防止未经授权的访问和不当使用。为防止用户个人信息在意外的、未经授权的情况下被非法访问、复制、修改、传送、遗失、破坏、处理或使用，本站已经并将继续采取以下措施保护您的个人信息：
        </p>
        <p>1)以适当的方式对用户的个人信息进行加密处理；</p>
        <p>2)在适当的位置使用密码对用户个人信息进行保护；</p>
        <p>3)其他的合理措施</p>
        <p>
          但是，相信您也理解，无论采取何种严密措施，都难免发生信息泄漏的情况，就此类情况的发生，本站将按照法律法规的要求及时向您告知，并尽最大的努力降低对您的影响，导致您的合法权益受损的，我们将承担相应的法律责任。
        </p>
        <p>
          您的帐号具有密码保护功能，以确保您的隐私和信息安全。如果您泄漏了密码，您可能丢失了您的个人识别信息，并且可能出现对您不利的后果。因此，无论任何原因危及您的密码安全，您应该立即通过本站联系方式和我们取得联系。
        </p>
        <p class="tit">个人信息的管理</p>
        <p>
          当您完成本站的帐号注册后，您可以浏览、修改或删除您提交给本站的个人信息。如果您需要浏览、删除、修改您的个人信息的，您可以通过以下方式操作：通过手机登录本站APP,点击进入“我”的页面，点击右上角“编辑资料“，进入后编辑您的个人信息。
        </p>
        <p>
          请注意：一般情况下，您可随时修改或删除自己提交的信息，但出于安全性和身份认证的考虑，您可能无法修改、删除注册时提供的某些初始注册信息及认证信息。
        </p>
        <p>
          您随时可以通过登录本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）注销您的账户，请联系网站联系方式由我们工作人员配置完成。
        </p>
        <p>
          在注销账户之后，我们将停止为您提供服务，并删除您的个人信息，法律法规另有规定的除外。
        </p>
        <p>未经请求的商业电子邮件 (UCE)</p>
        <p>
          为了提高我们的服务质量，您可能会接收到我们关于商业或营销的电子邮件、短信或电话。如您不想接听上述电话或收到短信、邮件，请您拨打客服电话4001520520或登录本网站注销您的注册账号。如您未注销您的账户，则视为您同意接听或接受本站给您的电话、短信及邮件。
        </p>
        <p>
          针对我们的服务或者他人为您提供的产品与服务，我们也会发送电子邮件以提供一般信息
        </p>
        <p>信息保护提示</p>
        <p>
          ·结束网络冲浪之后，请始终记得退出网站并关闭浏览器，以免其他人趁机获取您的信息和通信内容。
        </p>
        <p>
          ·本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）对所有链接网站均没有控制权，因而对这些网站（公司）的一切行为均不承担任何责任。
          当您使用其他网站服务时，请认真阅读其相关条款。
        </p>
        <p>
          ·请勿泄漏您的密码与会员身份详细信息。
          如果您的帐户被非法访问，请立即通知我们。 请时常更换密码。
        </p>
        <p>
          ·禁止在本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）网站发布个人联系信息。对于在我们网站上将个人联系信息公开给网站其他会员，我们不承担任何责任。个人信息包括姓名、电话号码和家庭住址等。
          注意 - 如果您自愿公开个人信息，有可能会被他人收集并加以利用。
          请务必谨慎！
        </p>
        <p>
          ·为保障本站及所有会员之利益，禁止会员在任何公共信息平台（包括但不限于任何网站、网页、报纸、期刊、电视、电影等各种类型的媒体）或在使用任何网络软件（包括但不限于QQ、MSN等即时通讯工具）的过程中将其在本网站的ID或昵称与相对应的会员的任何联系方式一并予以公布。
        </p>
        <p>
          ·如果您计划和在网上结识的人会面，一定要慎重。记住，网上交流与真实情况可能是两回事。如果您仍然决定与网友见面，请选择白天或人多的公众场合；并把这件事告诉自己的好朋友或家人。一定要告诉他们您要去哪里，您大概什么时候回来。
        </p>
        <p class="tit">对Cookies的使用</p>
        <p>
          我们使用您浏览器的一项叫做“a cookie”（“甜饼”记忆块）的功能来指定用户
          ID。Cookies
          能够在您访问我们的网站时识别出您连接到我们服务器的计算机，并扩展我们在网站范围内所提供服务的功能。您必须启用
          Web 浏览器的 Cookies 功能，才能使用 本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）网站的全部功能和服务。
        </p>
        <p>
          我们保留权利允许第三方（包括本公司或本公司关联企业、广告公司、广告联盟）在我们的网站上放置广告。这些公司可能会使用跟踪技术，例如Cookies，来收集用户对广告的浏览与点击信息。我们的网站不会提供任何个人资料给这些第三方，但是他们可能通过您或者其他使用您电脑的人对他们提供的广告的浏览、点击等动作，收集相关信息。他们也可能将这些信息与您在他们的广告网站上留下的个人信息结合进行分析。对于此类信息的收集与使用均受第三方隐私保护条款约束。这些信息收集的目的是为了这些第三方广告公司提供更有针对性的广告，提升广告效果。
        </p>
        <p>
          您有权选择接受或拒绝接受cookies。您可以通过修改浏览器的设置以拒绝接受cookies，但是我们需要提醒您，因为您拒绝接受cookies，您可能无法使用我们网站上依赖于cookies技术的全部或部分功能。
        </p>
        <p>本站使用协议</p>
        <p>
          您必须同意并遵守我们的《网站服务协议》，才可以使用我们网站特定领域的服务。
          在您同意《网站服务协议》之时，您已经同意我们按照本隐私政策来使用和披露您的个人信息。本隐私声明的全部条款属于《网站服务协议的一部份。
        </p>
        <p>关于未成年人使用网络的说明</p>
        <p>
          只有年满18岁的成年人士才可以加入本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）。我们不会向未成年人推销本网站，或者未经父母同意而有意地收集其未成年子女的个人信息。
        </p>
        <p>
          需要注意的是，与其他许多网站一样，本站（
          <a :href="orgInfo.mobileWebSite">{{ orgInfo.mobileWebSite }}</a
          >）难以鉴别使用本网站的会员或其他个人是否实际为未成年人，也无法确认在线请求的个人信息是否真实和正确，对于任何未成年人使用本网站之情况，一经我们发现，我们有权立即注销该会员帐号及其有关的一切信息，对于因此给该未成年人或其他会员造成的损害，应由该未成年人及其监护人承受，您在使用本网站时也已经认可在上述情况下我们不承担对任何损害的法律责任。
        </p>
        <p>不可抗力</p>
        <p>
          在使用本网站之前，您同意，对于因超出我们合理控制范围的任何事件或情况，包括但不限于战争、入侵、恐怖袭击、自然灾害或其他不可抗力，以及任何技术故障的发生、电气短路、第三方侵入本网站等情况所给您造成的任何损失或损害，我们不承担任何责任。
        </p>
        <p>隐私保护政策的修改</p>
        <p>
          本公司有权随时修改《个人信息保护政策》的任何条款，一旦《个人信息保护政策》的内容发生变动，本站将会直接在本网站上公布修改之后的《个人信息保护政策》，该公布行为视为本站已经通知您修改内容。本站也可通过其他适当方式向用户提示修改内容。如果您不同意本站对本《个人信息保护政策》相关条款所做的修改，您有权停止使用本站的服务。如果您继续使用本站服务，则视为您接受本公司对本协议相关条款所做的修改。
        </p>
        <p>联系我们</p>
        <p>
          如果您对本《个人信息保护政策》或本站的个人信息保护措施以及您在使用中的问题有任何意见和建议请和我们联系
        </p>
      </div>
    </div>

    <!-- 个人信息保护政策 end -->
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Agree",
  data() {
    return {
      type: 0
    };
  },
  computed: {
    ...mapState(["orgInfo"])
  },
  mounted() {
    const q = this.$route.query;
    console.log(this.orgInfo);
    if (q.type) {
      this.type = q.type;
    }
  },
  methods: {}
};
</script>
<style lang="less" scoped>
// 协议
.sign-register {
  padding: 0.2rem;
  position: relative;
  z-index: 1;

  .close-bold-icon {
    display: block;
    position: absolute;
    left: 50%;
    bottom: -1.2rem;
    margin-left: -0.4rem;
    width: 0.81rem;
    height: 0.82rem;
    // background: url("../../assets/images/icon/close-bold-icon.png") no-repeat
      // top center;
    background-size: auto 0.82rem;
  }
}

.agree-scroll .agree-tit {
  font-size: 0.34rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.agree-scroll .agree-text {
  font-size: 0.28rem;
  line-height: 1.8;

  .tit {
    font-size: 0.34rem;
    font-weight: bold;
  }

  .tit2 {
    font-size: 0.3rem;
    font-weight: bold;
  }

  a {
    color: red;
  }

  .name {
    color: red;
    font-weight: bold;
  }
}

.register-agree {
  color: #a1a1a1;
  font-size: 0.24rem;
  margin-top: 3rem;
  line-height: 0.4rem;
  a {
    color: #a1a1a1;
  }
}
</style>
